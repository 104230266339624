import React, { FC } from 'react'
import ElementProps from '../types/ElementProps'

import Dg5001 from './widget/Dg5001'
import Dg5003 from './widget/Dg5003'
import Dg5004 from './widget/Dg5004'
import Dg5005 from './widget/Dg5005'
import Dg5006 from './widget/Dg5006'
import Dg5007 from './widget/Dg5007'
import Dg5008 from './widget/Dg5008'
import Dg5009 from './widget/Dg5009'
import Dg5010 from './widget/Dg5010'
import Dg5011 from './widget/Dg5011'
import Dg5012 from './widget/Dg5012'
import Dg5013 from './widget/Dg5013'
import Dg5014 from './widget/Dg5014'
import Dg5015 from './widget/Dg5015'
import Dg5016 from './widget/Dg5016'
import Dg5017 from './widget/Dg5017'
import Dg5018 from './widget/Dg5018'
import Dg5019 from './widget/Dg5019'
import Dg5020 from './widget/Dg5020'
import Dg5021 from './widget/Dg5021'

const WidgetElement: FC<ElementProps> = (props) => {
    const elements: any = {
        Dg5001: Dg5001,
        Dg5002: Dg5001,
        Dg5003: Dg5003,
        Dg5004: Dg5004,
        Dg5005: Dg5005,
        Dg5006: Dg5006,
        Dg5007: Dg5007,
        Dg5008: Dg5008,
        Dg5009: Dg5009,
        Dg5010: Dg5010,
        Dg5011: Dg5011,
        Dg5012: Dg5012,
        Dg5013: Dg5013,
        Dg5014: Dg5014,
        Dg5015: Dg5015,
        Dg5016: Dg5016,
        Dg5017: Dg5017,
        Dg5018: Dg5018,
        Dg5019: Dg5019,
        Dg5020: Dg5020,
        Dg5021: Dg5021
    }
    const Tag = elements[props.type ?? '']
    return Tag ? <Tag {...props} />
        : <div>Unsuppoerted Element: {props.type}</div>
}

export default WidgetElement