import { FC } from 'react'
import EditElementProps from '../../types/EditElementProps'
import { getValue } from '../../utils/valueUtils'
// number-edit
const Dg16: FC<EditElementProps> = ({ properties, onChange, value }) => {
	const title = getValue(properties, 'title')
	const required = getValue(properties, 'required')
	const readonly = getValue(properties, 'readonly')
	const min = getValue(properties, 'min')
	const max = getValue(properties, 'max')
	const step = getValue(properties, 'step')
	return <input type="number" placeholder={title} required={required} readOnly={readonly}
		value={value ?? ''} onChange={e => onChange(Number(e.target.value))}
		step={step ? step : 'any'} min={min} max={max} />
}
export default Dg16