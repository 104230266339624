import { createContext } from 'react'
import { ModalContextType } from '../types/ModalContextType'
import { createPortal } from 'react-dom'
import { ItemProvider } from './ItemContext'

export const ModalContext = createContext<ModalContextType>({ accept: (data: any) => { }, cancel: () => { } })

interface ModalProviderProps {
    title: string
    visible: boolean
    layout: any
    item: any
    accept: any
    cancel: any
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ title, layout, item, visible, accept, cancel }) => {

    return <ModalContext.Provider value={{ accept, cancel }}>
        {visible ? createPortal(<div className="modal-wrapper">
            <div className='modal'>
                <h3>{title}</h3>
                {layout && <ItemProvider item={item} layout={layout} />}
                <button className='close' onClick={() => cancel()}>
                    <span className='fa fa-times'></span>
                </button>
            </div>
        </div>, document.body) : null}
    </ModalContext.Provider>
}
