import { FC, useContext, useState } from 'react'
import EditElementProps from '../../types/EditElementProps'
import MediaModal from '../../modal/MediaModal'
import { SiteContext } from '../../contexts/SiteContext'
import { getValue } from '../../utils/valueUtils'
import { ItemContext } from '../../contexts/ItemContext'
/**
 * file-edit
 * @param param0 
 * @returns 
 */
const Dg18: FC<EditElementProps> = ({ properties, onChange, value }) => {
	const { item } = useContext(ItemContext)
	const { site } = useContext(SiteContext)
	const [visible, setVisible] = useState(false)
	const multi = getValue(properties, 'multi')
	var activePath = '/'
	const upload = function (input: any) {
		var fldValId = input.name
		var uploadFile = function (j: number) {
			if (input.files.length > j) {
				var file = input.files[j]
				var fd = new FormData()
				var prefix = site.param.media ? site.param.media : '/upload'
				var uploadPath = prefix + activePath + file.name
				fd.append("file", file)
				fetch(uploadPath,
					{
						method: "POST",
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json'
						}, body: fd
					}).then(function () {
						if (item.fields) {
							item.fields[properties.id][fldValId] = prefix + activePath + file.name
						}
						if (properties.multi) {
							fldValId = onChange()
							uploadFile(j + 1)
						}
					})
			}
		}
		uploadFile(0)
	}
	const getFile = function () {
		setVisible(true)
	}
	const handleAccept = (url: any) => {
		onChange(url)
		setVisible(false)
	}
	const remove = () => onChange(null)

	return <div className="file-edit">
		{value && <img src={value} alt={value} className="img-responsive" />}
		{multi ? <input type="file" title="Шинэ зураг оруулах"
			onChange={() => { upload(this) }} multiple />
			: <input type="file" title="Шинэ зураг оруулах"
				onChange={() => { upload(this) }} />}
		<i className="fa fa-upload"></i>
		<div className="btn-group">
			<button type="button" className="btn btn-primary btn" onClick={getFile} title="Өмнө нь оруулсан зургаас ашиглах"><i
				className="fa fa-cloud-upload"></i></button>
			{value && <button type="button" className="btn btn-danger btn" onClick={remove} title="Зургийг мэдээнээс хасах"><i
				className="fa fa-trash"></i></button>}
		</div>
		<MediaModal src={value} accept={handleAccept} cancel={() => setVisible(false)} visible={visible} />
	</div>
}
export default Dg18