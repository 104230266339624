import { useNavigate } from 'react-router-dom'
import * as MeService from '../services/MeService'
import { getValue } from '../utils/valueUtils'
import { useContext } from 'react'
import { UserContext } from '../contexts/UserContext'

const Logout = () => {
    const navigate = useNavigate()
    const { setUser } = useContext(UserContext)
    MeService.signOut().then(async () => {
        const user = await (await MeService.get()).json()
        user.type = getValue(user, 0)
        setUser(user)
        navigate('/')
    })
    return <></>
}
export default Logout