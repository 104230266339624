import { FC } from 'react'
import EditElementProps from '../../types/EditElementProps'
import { getValue } from '../../utils/valueUtils'
// pair-edit
const Dg8: FC<EditElementProps> = ({ properties, onChange, value }) => {
	const title = getValue(properties, 'title')
	const options = getValue(properties, 'options')
	return <select value={value ?? ''} onChange={e => onChange(e.target.value)}>
		<option value="">---{title}---</option>
		{options && options.map((option: any[]) => <option key={option[0]} value={option[0]}>{option[1]}</option>)}
	</select>
}
export default Dg8