import { FC } from 'react'
import ElementProps from '../../types/ElementProps'
/**
 * html-view
 * @param param0 
 * @returns 
 */
const Dg1002: FC<ElementProps> = ({ properties, value }) => {
    return <div className={properties.class} dangerouslySetInnerHTML={{ __html: value }} />
}
export default Dg1002