import React, { FC, useEffect, useState } from 'react'
import { ModalProvider } from '../contexts/ModalContext'
import * as valueUtils from '../utils/valueUtils'

const getDirectory = function (url: string) {
	var path = 'media'
	if (url && url.substring(0, path.length) === path) {
		path = url.substring(0, url.indexOf('/'))
	}
	return path
}

const MediaModal: FC<MediaModalProps> = ({ visible, src, accept, cancel }) => {
	const layout = require('../json/media.json')
	const title = 'Медиа'
	const _item = { values: [{ field: "directory", value: getDirectory(src) }, { field: "src", value: src }] }
	const handleAccept = () => {
		accept(valueUtils.getValue(_item, 'src'))
	}
	return <ModalProvider layout={layout} item={_item}
		title={title} visible={visible}
		accept={handleAccept} cancel={cancel} />
	// const height = window.innerHeight - 150

	/*const ok = function () {
		accept('TODO: path')
	}

	return visible && <>
		<iframe src={src} width="100%" height={height}></iframe>
		<div className="input-group">
			<span className="input-group-addon"><i className="fa fa-link"></i></span>
			<input className="form-control" type="text" id="modalMediaUrl" ng-model="mediaUrl" />
			<span className="input-group-btn">
				<button className="btn btn-primary" onClick={ok}><i className="fa fa-check"></i> Сонгох</button>
				<button className="btn btn-warning" onClick={cancel}>Болих</button>
			</span>
		</div>
	</>*/
}
type MediaModalProps = {
	visible: boolean
	src: string
	accept: any
	cancel: any
}
export default MediaModal