import { FC } from 'react'
import ElementProps from '../../types/ElementProps'
/**
 * link-view
 * @param param0 
 * @returns 
 */
const Dg1051: FC<ElementProps> = ({ properties, value }) => {
    return <a href={value} className={properties.class}>{value}</a>
}
export default Dg1051