import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Value from '../types/Value'
import { getCategory, getCategories, getValue } from '../utils/valueUtils'
import Element from '../components/Element'
import { SiteContext } from '../contexts/SiteContext'

function Category() {
    const [title, setTitle] = useState('')
    //const [category, setCategory] = useState({})
    const [properties, setProperties] = useState({})
    const { categoryId } = useParams()
    const { site } = useContext(SiteContext)
    useEffect(() => {
        var category: any = getCategory(site, Number(categoryId))
        document.title = getValue(category, 'title')
        var properties = {
            values: [
                { field: "type", value: 5007 },
                { field: "layout", value: "teaser" },
                { field: "order", value: "-created" },
                { field: "pagination", value: 1 },
                { field: "count", value: 20 },
                { field: "column", value: 4 }] as Value[]
        }
        if (categoryId != null) {
            properties.values.push({
                field: "category", value: categoryId
            } as Value)
        }
        var search = new URL(window.location.href).searchParams.get('search')
        if (search != null) {
            properties.values.push({
                field: "search", value: '%' + search + '%'
            } as Value)
            if (categoryId == null) {
                properties.values.push({
                    field: "category",
                    value: getCategories(site).filter((category: any) => category.id > 0 || category.id === -1)
                } as Value)
                document.title = search
            }
        }
        //setCategory(category)
        setTitle(document.title)
        setProperties(properties)
    }, [site, categoryId])
    return <div className="teaser-view">
        <h1>{title}</h1>
        <Element properties={properties} />
    </div>
}
export default Category