import { FC, useContext } from 'react'
import * as valueUtils from '../utils/valueUtils'

import Dg2 from './edit/Dg2'
import Dg3 from './edit/Dg3'
import Dg4 from './edit/Dg4'
import Dg5 from './edit/Dg5'
import Dg6 from './edit/Dg6'
import Dg7 from './edit/Dg7'
import Dg8 from './edit/Dg8'
import Dg9 from './edit/Dg9'
import Dg10 from './edit/Dg10'
import Dg11 from './edit/Dg11'
import Dg14 from './edit/Dg14'
import Dg15 from './edit/Dg15'
import Dg16 from './edit/Dg16'
import Dg17 from './edit/Dg17'
import Dg18 from './edit/Dg18'
import Dg20 from './edit/Dg20'
import Dg30 from './edit/Dg30'
import Dg40 from './edit/Dg40'
import Dg41 from './edit/Dg41'
import Dg42 from './edit/Dg42'
import Dg43 from './edit/Dg43'
import Dg44 from './edit/Dg44'
import Dg50 from './edit/Dg50'
import ElementProps from '../types/ElementProps'
import { ItemContext } from '../contexts/ItemContext'

const EditElement: FC<ElementProps> = ({ properties, type }) => {
    const { item, setItem, refreshEmptyValue } = useContext(ItemContext)
    const title = valueUtils.getValue(properties, 'title')
    // Утга өөрчлөгдөх тоолонд DOM шинэчлэхийн тулд values-г state-д хийлээ.
    const elements: any = {
        Dg2: { tag: Dg2, isNative: false },
        Dg3: { tag: Dg3, isNative: false },
        Dg4: { tag: Dg4, isNative: false },
        Dg5: { tag: Dg5, isNative: false },
        Dg6: { tag: Dg6, isNative: false },
        Dg7: { tag: Dg7, isNative: false },
        Dg8: { tag: Dg8, isNative: false },
        Dg9: { tag: Dg9, isNative: false },
        Dg10: { tag: Dg10, isNative: false },
        Dg11: { tag: Dg11, isNative: false },
        Dg14: { tag: Dg14, isNative: true },
        Dg15: { tag: Dg15, isNative: false },
        Dg16: { tag: Dg16, isNative: false },
        Dg17: { tag: Dg17, isNative: false },
        Dg18: { tag: Dg18, isNative: false },
        Dg20: { tag: Dg20, isNative: false },
        Dg30: { tag: Dg30, isNative: false },
        Dg40: { tag: Dg40, isNative: false },
        Dg41: { tag: Dg41, isNative: false },
        Dg42: { tag: Dg42, isNative: false },
        Dg43: { tag: Dg43, isNative: false },
        Dg44: { tag: Dg44, isNative: false },
        Dg50: { tag: Dg50, isNative: false }
    }
    const element = elements[type ?? '']

    const handleNativeChange = (data: any) => setItem({ ...item, [properties.id]: data })

    const handleChange = (value: any, data: any) => {
        value.value = data
        refreshEmptyValue(properties)
    }
    return element ? <div className={properties.class}>
        <label>{title}</label>
        {element.tag.Before && <element.tag.Before properties={properties} />}
        {element.isNative ?
            <element.tag properties={properties} value={(item as any)[properties.id]} onChange={handleNativeChange} />
            : item.values?.filter((value: any) => value.field === properties.id).map((value: any, i: number) =>
                <element.tag key={i} properties={properties} value={value.value} id={value.id}
                    onChange={(data: any) => handleChange(value, data)} />)}
        {element.tag.After && <element.tag.After properties={properties} />}
    </div> : <div>Unsupported Element: {type}</div>
}

export default EditElement