import { FC } from 'react'
import ElementProps from '../../types/ElementProps'
import { getValue } from '../../utils/valueUtils'
/**
 * text-view
 * @param param0 
 * @returns 
 */
const Dg1001: FC<ElementProps> = ({ properties, value }) => {
    const Tag = getValue(properties, 'tag')
    return Tag ? <Tag className={properties.class}>{value}</Tag> : <>{value}</>
}
export default Dg1001