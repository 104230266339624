import { useParams, useLoaderData } from 'react-router-dom'
import Item from '../types/Item'
import { getCategory, getValue } from '../utils/valueUtils'
import { useContext, useEffect, useState } from 'react'
import { SiteContext } from '../contexts/SiteContext'
import { ItemProvider } from '../contexts/ItemContext'

function Page() {
  const { site, getItemLayout } = useContext(SiteContext)
  const params = useParams()
  const item = useLoaderData() as Item
  const [layout, setLayout] = useState<any>()
  const layoutName = params.layout ?? 'full'
  document.title = getValue(item, 100)

  useEffect(() => {
    // Мэдээллийн ангиллаас хамаарч харуулах хэлбэрийг өөрчилнө
    const category = getCategory(site, item.category)
    if (category) {
      setLayout(getItemLayout(category, layoutName))
    }
  }, [site, layoutName, item.category])

  if(layout) {
    const classValue = layout.values.find((value: any) => value.field === 'class')
    const className = layoutName + ' page' + item?.id
    if (classValue) {
      classValue.value = className
    } else {
      layout.values.push({ field: 'class', value: className })
    }
  }

  return item && layout && <ItemProvider item={item} layout={layout} />
}
export default Page