import * as Service from './Service'

/**
 * Файл удирдах сервис
 */
export const postfix = 'dir.json'
/**
 * Өгөгдсөн замд байгаа файл болон хавтсын жагсаалтыг авна
 * @param {string} path Зам
 * @returns Файл болон хавтсын жагсаалт
 */
export const dir = (path: string) => Service.get(path + postfix, undefined)
/**
 * Өгөгдсөн зам дээр хавтас үүсгэнэ
 * @param {string} path Зам
 * @param {string} name Хавтасны нэр
 * @returns Төлөв
 */
export const mkdir = (path: string, name: string) => Service.add(path + postfix, { title: name })
/**
 * Өгөгдсөн хавтасны нэрийг солино
 * @param {string} path Зам
 * @param {string} name Шинэ нэр
 * @returns Төлөв
 */
export const rename = (path: string, name: string) => Service.edit(path + postfix, undefined, { title: name })
/**
 * Өгөгдсөн хавтсыг устгана
 * @param {string} path Зам
 * @returns Төлөв
 */
export const rmdir = (path: string) => Service.remove(path + postfix, undefined)

export const upload = (path: string, file: any) => {
    var fd = new FormData()
    fd.append("file", file)
    return Service.post(path, fd)
}
/**
 * Мэдээлэл устгана
 * @param {number} id Устгах мэдээллийн дугаар
 * @returns 
 */
export const remove = (path: string) => Service.remove(path, undefined)
/**
 * Загварын тохиргооны хуудасны бүтэц авах сервис
 */
export const getConfigLayout = () => Service.get('upload/theme/config.json', undefined)