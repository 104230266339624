import { useContext, useEffect, useState } from 'react'
import * as MediaService from '../services/MediaService'
import { SiteContext } from '../contexts/SiteContext'
import { ItemProvider } from '../contexts/ItemContext'

function Theme() {
  const [layout, setLayout] = useState({ items: null })
  const { site } = useContext(SiteContext)
  document.title = 'Загвар'

  useEffect(() => {
    MediaService.getConfigLayout()
      .then(response => response.json()).then(layout => {
        setLayout(layout)
      })
  }, [])
  return layout.items && <ItemProvider item={site} layout={layout} />
}
export default Theme