import { createContext, ReactNode, useEffect, useState } from 'react'
import { SiteContextType } from '../types/SiteContextType'
import * as SiteService from '../services/SiteService'
import Site from '../types/Site'
import { getParentCategory, getValue } from '../utils/valueUtils'

export const SiteContext = createContext<SiteContextType>({ site: {} as Site, setSite: () => { }, getItemLayout: () => { } })

interface SiteProviderProps {
    children: ReactNode
}

export const SiteProvider: React.FC<SiteProviderProps> = ({ children }) => {
    // Вебийн тохиргоог агуулна.
    const [site, setSite] = useState<Site>({} as Site)
    const [loading, setLoading] = useState<Boolean>(true)

    useEffect(() => {
        const init = async () => {
            // Вебийн тохиргоог авна.
            setSite(await (await SiteService.get()).json())
            setLoading(false)
        }
        init()
    }, [])


    /**
     * Мэдээллийн бүтцийг авна
     * @param category Ангилал
     * @param layoutType Бүтцийн төрөл
     * @returns Мэдээллийн бүтэц
     */
    const getItemLayout = (category: any, layoutType: any) => {
        do {
            var values = category.values.filter((value: any) => value.field === 'layout' && value.value.name === layoutType)
            if (values.length > 0) {
                return values[0].value
            }
            category = getValue(category, 'inherit') ? getParentCategory(site, category.id) : null
        } while (category)
        return null
    }

    const value = { site, setSite, getItemLayout }

    return loading ? null :
        <SiteContext.Provider value={value}>
            {children}
        </SiteContext.Provider>
}
