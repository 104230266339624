import { useState, FC, useContext } from 'react'
import EditElementProps from '../../types/EditElementProps'
import * as ItemService from '../../services/ItemService'
import { getValue } from '../../utils/valueUtils'
import ItemModal from '../../modal/ItemModal'
import Item from '../../types/Item'
// item-edit
const Dg5: FC<EditElementProps> = ({ properties, onChange, value }) => {
	const [items, setItems] = useState([])
	const [_item, setItem] = useState({} as Item)
	const [visible, setVisible] = useState(false)
	const [selected, setSelected] = useState({} as any)
	const refreshItems = (searchText: any) => {
		var filter: any = {
			limit: 10,
			offset: 0
		}
		//searchText == null || searchText.length == 0 ? {} : { text: searchText + '%' }
		var cat = getValue(properties, 'category')
		var q = {
			category: cat == null ? -1 : cat
		}
		filter.q = JSON.stringify(q)
		ItemService.query(filter).then(response => response.json()).then(function (data) {
			setItems(data.content)
		})
	}
	const add = function () {
		setVisible(true)
		setItem({ status: 30, category: properties.form.category, values: [] })
	}
	const modalClose = (result: any) => {
		setItems(items.concat(result))
		onChange(result.id)
	}
	return <div className="input-group">
		<div>{value}</div>
		<span className="input-group-btn">
			<a href="" className="btn btn-success" onClick={add}><i className="fa fa-plus"></i></a>
		</span>
		<ul className="dropdownlist">
			<li><input value={selected.id + ' ' + selected.title} onChange={refreshItems} /></li>
			{items.map((item: any) => <li key={item}>
				{item.id}. {item.title}
			</li>)}
		</ul>
		<ItemModal visible={false} onClose={modalClose} item={_item} />
	</div>
}
export default Dg5