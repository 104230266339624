import { FC } from 'react'
import ElementProps from '../../types/ElementProps'
import {getValue} from '../../utils/valueUtils'
/**
 * image-view
 * @param param0 
 * @returns 
 */
const Dg1003: FC<ElementProps> = ({ properties, value }) => {
  let image
  const title = getValue(properties, 'title')
  const mode = getValue(properties, 'mode')
  if (mode) {
    var height = getValue(properties, 'height')
    var width = getValue(properties, 'width')
    var urlPrefix = '/image/' + width + mode + height + '/'
    image = value.replace('/upload/', urlPrefix)
  } else {
    image = value
  }
  return <img src={image} alt={title} className={properties.class} />
}

export default Dg1003