/**
 * Сервертэй харьцах ерөнхий сервис
 */
const buildUrl = (alias: string, params: any = {}) => {
    const url = new URL(window.location.protocol + '//' + window.location.hostname + '/' + alias)
    Object.keys(params).forEach((key: string) => url.searchParams.append(key, params[key]))
    return url
}
/**
 * Үндсэн HTTP header тохиргоог авна
 * @returns Brearer токентой json форматаар өгөгдөл солилцох тохиргоо
 */
const getHeaders = () => {
    var headers: any = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    const token = localStorage.getItem('token')
    if (token && token !== '') {
        headers.Authorization = 'Bearer ' + token
    }
    return headers
}
const fetchData = (url: URL, method: string, body = null) => fetch(url, { method, headers: getHeaders(), body: body ? JSON.stringify(body) : null })
/**
 * Мэдээллийн жагсаалт авна
 * @param {*} params Мэдээллийг хайлт, шүүлтийн тохиргоо
 * @returns Мэдээллийн жагсаалт
 */
export const query = (alias: string, params: any) => fetchData(buildUrl(alias, params), "GET")
/**
 * Өгөгдсөн дугаартай мэдээллийг авна
 * @param {number} id Авах мэдээллийн дугаар
 * @returns Мэдээлэл
 */
export const get = (alias: string, id: number | undefined) => fetchData(buildUrl(id === undefined ? alias : alias + '/' + id), "GET")
/**
 * Мэдээлэл нэмнэ
 * @param {object} item Мэдээлэл
 * @returns 
 */
export const add = (alias: string, item: any) => fetchData(buildUrl(alias), "POST", item)
/**
 * Мэдээлэл засна
 * @param {object} item Мэдээлэл
 * @returns 
 */
export const edit = (alias: string, id: number | undefined, item: any) => fetchData(buildUrl(id === undefined ? alias : alias + '/' + id), "PUT", item)
/**
 * Мэдээлэл устгана
 * @param {number} id Устгах мэдээллийн дугаар
 * @returns 
 */
export const remove = (alias: string, id: number | undefined) => fetchData(buildUrl(id === undefined ? alias : alias + '/' + id), "DELETE")
/**
 * Мэдээлэл нэмнэ
 * @param {object} item Мэдээлэл
 * @returns 
 */
export const post = (alias: string, body: any) => fetch(buildUrl(alias), { method: 'POST', headers: getHeaders(), body })