import { FC } from 'react'
import { redirect } from 'react-router-dom'
import WidgetProps from '../../types/WidgetProps'
/**
 * Хайлт
 */
const Dg5009: FC<WidgetProps> = ({ properties }) => {
    let search = new URL(window.location.href).searchParams.get('search')
    const handleSubmit = () => {
        redirect('items.html?search=' + search)
    }
    return <form className={properties.class} onSubmit={handleSubmit}>
        <input type="text" value={search || ''} />
        <button type="submit"></button>
    </form>
}
export default Dg5009