import { useContext, useEffect } from "react"
import { SiteContext } from "../contexts/SiteContext"
import { getValue } from "../utils/valueUtils"
import { useNavigate } from "react-router-dom"

function Home() {
  const { site } = useContext(SiteContext)
  const navigate = useNavigate()
  const id = getValue(site, 51)
  useEffect(() => {
    navigate(`/items/${id}.html`)
  }, [id])
  return <></>
}
export default Home