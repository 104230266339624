import { FC } from 'react'
import ElementProps from '../../types/ElementProps'
/**
 * count-view
 * @param param0 
 * @returns 
 */
const Dg1200: FC<ElementProps> = ({ value }) => {
    return <>{value}</>
}
export default Dg1200