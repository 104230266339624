import { FC } from 'react'
import * as valueUtils from '../utils/valueUtils'
import { ModalProvider } from '../contexts/ModalContext'

const PermissionModal: FC<PermissionModalProps> = ({ title, item, visible, onAccept, onCancel }) => {
    const layout = require('../json/permission.json')
    const _item = { values: [{ field: "permission", value: item }] }
    const handleClose = () => {
        onAccept(valueUtils.getValue(_item, 'permission'))
    }
    return <ModalProvider layout={layout} item={_item}
        title={title} visible={visible}
        accept={handleClose} cancel={onCancel} />
}
type PermissionModalProps = {
    visible: boolean
    onAccept: any
    onCancel: any
    item: any
    title: string
}

export default PermissionModal