import { useState, useEffect, FC, useContext } from 'react'
import Element from '../Element'
import WidgetProps from '../../types/WidgetProps'
import { ItemContext } from '../../contexts/ItemContext'
import { handleRequestError } from '../../utils/responseUtils'
import { ModalContext } from '../../contexts/ModalContext'
/**
 * Маягт
 */
const Dg5006: FC<WidgetProps> = ({ properties }) => {
    const { item, setEditable, save } = useContext(ItemContext)
    const { accept } = useContext(ModalContext)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setEditable(true)
    })

    /**
     * Мэдээллийн түлхүүр утга болон өөрчлөгдсөн хэсгийг серверт илгээнэ
     * @param {*} e 
     */
    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (e.currentTarget !== e.target) {
            // Сонсож байгаа элемент, дуудсан элемент 2 нь ижил биш бол
            // ямар ч үйлдэл хийхгүй.
            // 2 формын submit үйлдэл давхар дуудагдахыг зогсоов.
            console.log('submit skip duplicate')
            return
        }
        setIsLoading(true)
        save().then(() => {
            setIsLoading(false)
            accept(item)
        }).catch((e: any) => {
            setIsLoading(false)
            handleRequestError(e)
        })
    }

    return <form className={properties.class + (isLoading ? ' loading' : '')} onSubmit={handleSubmit}>
        {properties.items.map((element: any, i: number) =>
            <Element key={i} properties={element} />)}
    </form>
}

export default Dg5006