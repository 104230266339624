import { FC, useContext, useEffect, useState } from 'react'
import { Link, useLocation } from "react-router-dom"
import WidgetProps from '../../types/WidgetProps'
import Site from '../../types/Site'
import { getCategory, getValue } from '../../utils/valueUtils'
import { SiteContext } from '../../contexts/SiteContext'
import { UserContext } from '../../contexts/UserContext'
/**
 * Цэсийн тохиргоо
 */
// menu
const Dg5010: FC<WidgetProps> = ({ properties }) => {
    const { site } = useContext(SiteContext)
    const { user } = useContext(UserContext)
    const location = useLocation()
    const [menu, setMenu] = useState<any>({})
    const [items, setItems] = useState([])
    const menuName = getValue(properties, 'menu')
    const toggleClass = 'open-' + menuName
    // Цэсийг анх ачаалах үед тохиргооны дагуу цэсийн модыг үүсгэнэ.
    useEffect(() => {
        // Цэсийн мэдээллийг оруулж дахин дүрсэлнэ.
        if (menuName) {
            setMenu(initMenu(user, site, menuName))
        } else {
            console.log('ERROR: menu property required.', properties)
        }
    }, [user])
    // Хуудас солигдох бүрт идэвхтэй цэсийг өөрчилнө.
    useEffect(() => {
        const level = getValue(properties, 'level')
        const depth = getValue(properties, 'depth')
        setItems(menu.items ? refreshMenu(menu.items, level, depth, window.location.pathname) : [])

    }, [menu.items, location.pathname])

    // Цэсийг нээж хаах үйлдэл хийнэ.
    const toggle = () => {
        if (document.body.classList.contains(toggleClass)) {
            document.body.classList.remove(toggleClass)
        } else {
            document.body.classList.add(toggleClass)
        }
    }
    return <div className={properties.class}>
        <button type="button" className="toggler" onClick={toggle}></button>
        <ul>{renderItems(items)}</ul>
    </div>
}
function removeClass(item: any, cls: any) {
    item.class = item.class.replace(cls, '')
}
function refreshClasses(items: any, activeUrl: any) {
    items.forEach((item: any) => {
        removeClass(item, ' active')
        removeClass(item, ' hasActive')
        if (item.link === activeUrl) {
            item.class += ' active'
            var parent = item.parent
            while (parent) {
                parent.class += ' hasActive'
                parent = parent.parent
            }
        }
        if (item.items && item.items.length > 0) {
            item.items = refreshClasses(item.items, activeUrl)
        }
    })
    return items
}
/**
 * Хандах эрхтэй эсэхийг шалгана
 * @param user хэрэглэгч
 * @param site веб сайт
 * @param item цэс
 * @returns Хандах эрхтэй бол true, эрхгүй бол false
 */
const isPermitted = (user: any, site: any, item: any) =>
    (getValue(item, 'hasCategory') && !site.param.hasCategory) ||
        (getValue(item, 'hasOrder') && !site.param.hasOrder) ||
        (getValue(item, 'hasOption') && !site.param.hasOption) ? false :
        (getValue(item, 'hide') & (1 << user.type)) === 0

function loadItems(item: any) {
    return []
}
function loadCategories(menuName: any, category: any) {
    const item: any = {
        title: getValue(category, 'title'),
        link: getItemsLink(menuName, category.id),
        class: ''
    }
    if (category.name && category.name.length > 0) {
        item.class = ' ' + category.name
    }
    if ('items' in category) {
        if (item.class.indexOf('parent') < 0) {
            item.class += 'parent'
        }
        item.items = category.items.map((child: any) => loadCategories(menuName, child))
    }
    return item
}
// Цэсийн тохиргооны дагуу ангилал, хуудасны автомат цэгийг тооцож
// харах эрхтэй цэсүүдийг хэвлэхэд зориулсан мод обьектыг үүсгэнэ.
function initMenu(user: any, site: Site, menuName: any) {
    // Цэсийн мэдээллийг вебийн тохиргооноос авна.
    const defaultMenus = site.default.values.filter((value: any) => value.field === 2 && value.value.name === menuName)
    const menus = site.values.filter((value: any) => value.field === 2 && value.value.name === menuName)
    // Цэсийн тохиргоо байхгүй бол хоосон утга буцаана.
    if (menus.length === 0 && defaultMenus.length === 0) {
        return null
    }
    // Нэр бүхий цэсийг эхлээд үндсэн тохиргооноос авна. Байхгүй бол вебийн тохиргооноос авна.
    const menu = defaultMenus.length > 0 ? defaultMenus[0].value : menus[0].value
    // Харах эрхтэй цэсүүдийг ялгаж авна.
    //menu.items = getPermittedMenu(user, site, menu)
    // Цэсийн обьектыг үүсгэнэ.
    const menuObject = initMenuItem(user, site, menuName, menu)
    // Эх зангилааг тохируулна.
    const setParent = (parent: any) => {
        if (parent.items) {
            parent.items.forEach((item: any) => {
                item.parent = parent
                setParent(item)
            })
        }
    }
    setParent(menuObject)
    return menuObject
}
const getItemsLink = (menuName: any, id: number) => {
    if (id === -1) {
        return menuName !== 'admin_menu' ? '/pages.html' :
            '/pages/table.html'
    } else if (id === -3) {
        return menuName !== 'admin_menu' ? '/users.html' :
            '/users/table.html'
    } else {
        return menuName !== 'admin_menu' ? `/categories/${id}/items.html` :
            `/categories/${id}/table.html`
    }
}
function initMenuItem(user: any, site: Site, menuName: any, item: any) {
    // Харах эрхгүй бол алгасна.
    if (!isPermitted(user, site, item)) {
        return null
    }
    // Гарчиг, css класс, шинэ цонход нээх эсэх мэдээллийг агуулсан
    // цэсийн шинэ обьект үүсгэнэ.
    let result: any = {
        class: getValue(item, 'class') ?? '',
        title: getValue(item, 'title'),
        blank: getValue(item, 'blank'),
        link: null,
        image: null
    }
    // Хэрвээ дэд цэс нь олон баганад хуваагдаж харагдах бол
    // хувааж харуулах css классыг нэмнэ.
    const columns = getValue(item, 'columns')
    if (columns > 1) {
        result.class += 'has-' + columns + '-cols'
    }
    // Хэрвээ цэсэнд зураг харуулах бол
    // зургийг цэсийн обьектод нэмнэ.
    const image = getValue(item, 'image')
    if (image) {
        result.image = image
    }
    // Цэсийн холбогдсон мэдээлэл, эсвэл ангиллын дугаарыг авна.
    const id = getValue(item, 'id')
    // Хэрвээ цэсийн төрөл нь
    switch (getValue(item, 'type')) {
        case 1: // холбоос бол
            result.link = getValue(item, 'link')
            if (result.link.length > 0 && result.link.charAt(0) !== '/' &&
                result.link.length > 7 && result.link.substr(0, 4) !== 'http') {
                result.link = '/' + result.link
            }
            break
        case 2: // ангилал бол
            if (getValue(item, 'load')) { // Дэд ангиллуудыг цэс болгож харуулах бол
                var category = getCategory(site, id)
                if (category !== null) {
                    let className = result.class
                    result = { ...loadCategories(menuName, category) }
                    result.class += ' ' + className
                }
            } else { // Дэд ангиллуудыг цэс болгож харуулахгүй бол
                // Ангиллын дугаарыг цэсийн css класст нэмнэ.
                result.class += ' cat' + id
                // Ангилал харуулах хуудасны хаягийг цэсийн холбоос болгоно.
                result.link = getItemsLink(menuName, id)
            }
            break
        case 3: // мэдээлэл бол
            // Мэдээллийн дугаарыг цэсийн css класст нэмнэ.
            result.class += ' page' + id
            // Мэдээллийн хаягийг цэсийн холбоос болгоно.
            result.link = '/items/' + id + '.html'
            // Дэд мэдээллүүдийг цэс болгож харуулах бол
            if (getValue(item, 'load')) {
                // Дэд мэдээллүүдийг цэс болгож нэмнэ.
                result.items = loadItems(item)
            }
            break
        default: // холбоосгүй бол
            // result.link = '/new/' + id + '.html'
            break
    }
    // Хэрвээ дэд цэстэй бол дэд цэсүүдийн обьектыг үүсгэж нэмнэ.
    if ('items' in item) {
        let items = item.items.map((child: any) => initMenuItem(user, site, menuName, child)).filter((child: any) => child !== null)
        items.forEach((item: any) => {
            item.parent = result
        })
        if (items.length > 0) {
            result.class += ' parent'
            result.items = result.items ? result.items.concat(items) : items
            if (!result.link) {
                // Эх цэс нь дэд цэсийн эхний цэстэй ижил холбоостой болно.
                result.link = result.items[0].link
            }
        }
    }
    return result
}
function getLevelItems(items: any, level: any, i: any) {
    return items.filter((item: any) => item.class.indexOf('active') >= 0 || item.class.indexOf('hasActive') >= 0)
        .forEach((item: any) => level === ++i ? item.items : getLevelItems(item.items, level, i))
}
function getDepthItems(items: any, level: any, i: any) {
    var result = []
    i++
    for (let item of items) {
        // TODO: var clone = angular.copy(item)
        var clone = { ...item }
        if (level > i) {
            var children = getDepthItems(item.items, level, i)
            if (children.length > 0) {
                clone.items = children
            } else {
                delete clone.items
            }
        } else {
            delete clone.items
        }
        result.push(clone)
    }
    return result
}
function refreshMenu(items: any, level: any, depth: any, pathname: any): any {
    refreshClasses(items, pathname)
    items = level > 0 ? getLevelItems(items, level, 0) : items
    items = depth > 0 ? getDepthItems(items, level, 0) : items
    return [...items]
}
function renderItems(items: any) {
    return items.map((item: any, i: number) => <li key={i} className={item.class}>
        {item.blank ? <a href={item.link} title={item.title} target="_blank" rel="noopener noreferrer">{item.image && <img src={item.image} alt={item.title} />}{item.title}</a>
            : <Link to={item.link} title={item.title}>{item.image && <img src={item.image} alt={item.title} />}{item.title}</Link>}
        {item.items && <ul>{renderItems(item.items)}</ul>}
    </li>)
}
export default Dg5010