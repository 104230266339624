import { FC } from 'react'
import EditElementProps from '../../types/EditElementProps'
import { getValue } from '../../utils/valueUtils'
// binary-check-edit
// Сонголтын утгуудыг 1 битээр илэрхийлэн нийлүүлж бүхэл тоон утга болгоно.
const Dg44: FC<EditElementProps> = ({ properties, onChange, value }) => {
	const options = getValue(properties, 'options')

	return options && <div className='check-group'>
		{options.map((option: any[]) => <label key={option[0]}>
			<input type="checkbox" defaultChecked={(value & 1 << option[0]) === 1 << option[0]}
				onChange={e => onChange(e.target.checked ? value | 1 << option[0] : value & ~(1 << option[0]))} />{option[1]}
		</label>)}
	</div>
}
export default Dg44