import { FC } from 'react'
import ElementProps from '../../types/ElementProps'
/**
 * iframe-view
 * @param param0 
 * @returns 
 */
const Dg1050: FC<ElementProps> = ({ properties, value }) => {
    return <iframe src={value} title="" className={properties.class} />
}
export default Dg1050