import { useState, useEffect, FC } from 'react'
import WidgetProps from '../../types/WidgetProps'
import * as valueUtils from '../../utils/valueUtils'
// list
const Dg5005: FC<WidgetProps> = ({ properties }) => {
    const [current, setCurrent] = useState(0)
    const [timer, setTimer] = useState(null as any)
    let isSlide = false
    let className = ''
    var layout = valueUtils.getValue(properties, 'layout')
    var column = valueUtils.getValue(properties, 'column')
    const auto = valueUtils.getValue(properties, 'auto')
    const duration = valueUtils.getValue(properties, 'duration') ?? 5000
    const delay = valueUtils.getValue(properties, 'delay') ?? 0
    if (column > 1) {
        className += ' has-' + column + '-cols'
    }
    var items = valueUtils.getValues(properties, 'items')
    if (layout === 'slide' && items.length > 1) {
        isSlide = true
    }
    const prevIndex = (i: number) => i > 0 ? i - 1 : items.length - 1
    const nextIndex = (i: number) => i < items.length - 1 ? i + 1 : 0
    const run = function () {
        next()
    }
    const prev = () => {
        setCurrent(prevIndex(current))
        if (auto) {
            clearTimeout(timer)
            setTimer(setTimeout(run, duration))
        }
    }
    const next = () => {
        setCurrent(nextIndex(current))
        if (auto) {
            clearTimeout(timer)
            setTimer(setTimeout(run, duration))
        }
    }
    const goTo = (item: any) => {
        setCurrent(items.indexOf(item))
        if (auto) {
            clearTimeout(timer)
            setTimer(setTimeout(run, duration))
        }
    }
    useEffect(() => {
        if (layout === 'slide' && items.length > 1) {
            isSlide = true
            if (auto) {
                setTimer(setTimeout(run, duration + delay))
                return () => clearTimeout(timer)
            }
        }
    }, [])
    const i = prevIndex(current)
    var doubleItems = i == 0 ? [...items] : [...items.slice(i, items.length), ...items.slice(0, i)]
    return <div className={properties.class}>
        <div className="items">
            {doubleItems.map((item, i) => <div key={i} className="item" dangerouslySetInnerHTML={{ __html: item }}></div>)}
        </div>
        {isSlide && <div className="nav">
            <a onClick={prev} className="prev"></a>
            <div className="dots">
                {items.map((item: any, i: number) => <a key={i} className={i === current ? 'dot active' : 'dot'} onClick={() => { goTo(item) }}></a>)}
            </div>
            <a onClick={next} className="next"></a>
        </div>}
    </div>
}
export default Dg5005