import { FC } from 'react'
import WidgetProps from '../../types/WidgetProps'
import Element from '../Element'
import { getValue } from '../../utils/valueUtils'
// Мөр
const Dg5001: FC<WidgetProps> = ({ properties }) => {
    const Tag = getValue(properties, 'tag') ?? 'div'
    return <Tag className={properties.class}>
        {properties.items?.map((element: any, i: number) =>
            <Element key={i} properties={element} />)}
    </Tag>
}
export default Dg5001