import { useEffect, FC, useContext, useState } from 'react'
import EditElementProps from '../../types/EditElementProps'
import { SiteContext } from '../../contexts/SiteContext'
import { getValue, slugify } from '../../utils/valueUtils'
import MediaModal from '../../modal/MediaModal'
import { ItemContext } from '../../contexts/ItemContext'
// image-edit
const Dg9: FC<EditElementProps> = ({ properties, onChange, value }) => {
	const { item } = useContext(ItemContext)
	const { site } = useContext(SiteContext)
	const multi = getValue(properties, 'multi')
	const [visible, setVisible] = useState(false)
	var activePath = '/'
	useEffect(() => {
	}, [])
	const handleChange = (event: any) => {
		const value = event.target.value
		const uploadFile = (j: number) => {
			if (event.target.files.length > j) {
				var file = event.target.files[j]
				var fd = new FormData()
				let prefix = site.param.media ? site.param.media : '/upload'
				let prefix2 = site.param.media ? site.param.media : '/images/150t150'
				var uploadPath = prefix + activePath + slugify(file.name)
				fd.append("file", file)
				fetch(uploadPath,
					{
						method: "POST",
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json'
						}, body: fd
					}).then(function () {
						if (j === 0) {
							onChange(uploadPath)
						} else {
							if (item.values) {
								var values = item.values.filter((value: any) =>
									value == null && value.field === properties.id)
								values[0].value = uploadPath
							}
							onChange(uploadPath)
						}
						uploadFile(j + 1)
					})
			}
		}
		uploadFile(0)
	}
	const getImage = () => {
		setVisible(true)
	}
	const handleAccept = (url: any) => {
		onChange(url)
		setVisible(false)
	}
	const remove = () => onChange(null)

	return <div className="image-edit">
		{value && <img src={value} alt={value} className="img-responsive" />}
		<input type="file" title="Шинэ зураг оруулах" onChange={handleChange} multiple={multi} />
		<i className="fa fa-upload"></i>
		<div className="btn-group">
			<button type="button" className="btn btn-primary btn" onClick={getImage} title="Өмнө нь оруулсан зургаас ашиглах"><i
				className="fa fa-cloud-upload"></i></button>
			{value && <button type="button" className="btn btn-danger btn" onClick={remove} title="Зургийг мэдээнээс хасах"><i
				className="fa fa-trash"></i></button>}
		</div>
		<MediaModal visible={visible} src={value} accept={handleAccept} cancel={() => setVisible(false)} />
	</div>
}
export default Dg9