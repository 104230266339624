import { useState } from 'react'
import { Link, redirect } from 'react-router-dom'
import * as MeService from '../services/MeService'

const Password = () => {
    const [email, setEmail] = useState('')
    const newPassword = () => {
        MeService.newPass(email).then(function (value) {
            redirect('/')
        })
    }
    return <div className="login-box">
        <h1>Нууц үг сэргээх код авах</h1>
        <form role="form" onSubmit={newPassword} className="box">
            <div className="form-group">
                <label>Цахим шуудан</label>
                <input title="Цахим шуудан" type="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div className="form-actions">
                <button type="submit" className="btn btn-primary"><i className="fa fa-send"></i> Илгээх</button>
            </div>
            <p>
                <Link to="/">Нэвтрэх хуудас руу буцах</Link>
            </p>
        </form>
    </div>
}
export default Password