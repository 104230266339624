import { useContext, useEffect, useState } from 'react'
import * as SiteService from '../services/SiteService'
import { handleRequestError } from '../utils/responseUtils'
import { SiteContext } from '../contexts/SiteContext'
import { getValue } from '../utils/valueUtils'
import { useParams } from 'react-router-dom'
import { ItemProvider } from '../contexts/ItemContext'
import Item from '../types/Item'

/**
 * Хуудасны бүтэц өрнө
 * @param {*} props 
 * @returns 
 */
const PageLayout = () => {
  const { site } = useContext(SiteContext)
  const { valueId, nodeId } = useParams()
  const [item, setItem] = useState<Item>()
  const [title, setTitle] = useState()
  const [properties, setProperties] = useState<any>()
  useEffect(() => {
    // nodeId нь үсэг, тоо байж болно
    var values = site.values.filter(value => value.id === Number(valueId))

    if (values.length > 0) {
      var node = findNode(nodeId, values[0].value)
      if (node) {
        setTitle(getValue(node, 'title'))
        setProperties({
          id: 'layout',
          values: [{
            field: 'item',
            value: 4
          }, {
            field: 'field',
            value: 7
          }, {
            field: 'multi',
            value: true
          }, {
            field: 'type',
            value: 41
          }]
        })

        setItem(node)
      }
    }
  }, [nodeId, valueId])
  const save = () => {
    var clear = (category: any) => {
      if ('items' in category) {
        if (category.items.length > 0) {
          category.items.forEach((child: any) => {
            clear(child)
          })
        } else {
          delete category.items
        }
      }
      if ('path' in category) {
        delete category.path
      }
      if ('parent' in category) {
        delete category.parent
      }
    }
    var data = {
      id: site.id,
      category: site.category,
      values: site.values.filter(value => value.field === 1).map(value => {
        // TODO: var result = angular.copy(value)
        var result = value
        clear(result.value)
        return result
      })
    }
    SiteService.edit(data).then(res => res.json()).then(() => {
      alert('Амжилттай хадгаллаа.')
    }).catch(handleRequestError)
  }
  return <>
    <h1><i className="fa fa-cog fa-fw"></i> {title}</h1>
    <div className="box">
      {item && <ItemProvider item={item} layout={properties} />}
    </div>
    <div className="action">
      <a href="categories.html" className="btn btn-default"><i className="fa fa-chevron-left"></i> Болих</a>
      <button className="btn btn-primary" onClick={save}>Хадгалах</button>
    </div>
  </>
}

const findNode = (nodeId: any, node: any) => {
  if (node.id.toString() === nodeId) {
    return node
  } else if ('items' in node) {
    for (const child of node.items) {
      var result: any = findNode(nodeId, child)
      if (result) {
        return result
      }
    }
  }
}

export default PageLayout