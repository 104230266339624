import { FC, useContext, useState } from 'react'
import EditElementProps from '../../types/EditElementProps'
import { ItemContext } from '../../contexts/ItemContext'
// options-edit
const Dg2: FC<EditElementProps> = ({ properties, onChange }) => {
    const { item } = useContext(ItemContext)
    const [key, setKey] = useState('')
    const [value, setValue] = useState('')
    let values = item.fields && item.fields[properties.id]
    const add = () => {
        values[key] = value
    }
    const remove = (key: any) => {
        delete values[key]
    }
    return <>
        <div className="input-group" ng-repeat="(i, value) in values">
            <input type="text" className="form-control" value={values[0]} />
            <div className="input-group-append">
                <button className="btn btn-outline-secondary" type="button" onClick={remove}>
                    <span className="fa fa-times" title="Устгах"></span>
                </button>
            </div>
        </div>
        <div className="input-group">
            <input type="text" className="form-control" value={key} onChange={e => setKey(e.target.value)} />
            <input type="text" className="form-control" value={value} onChange={e => setValue(e.target.value)} />
            <div className="input-group-append">
                <button className="btn btn-outline-secondary" type="button" onClick={add}>
                    <span className="fa fa-plus" title="Нэмэх"></span>
                </button>
            </div>
        </div>
    </>
}
export default Dg2