import { FC } from 'react'
import ElementProps from '../../types/ElementProps'
/**
 * category-view
 * @param param0 
 * @returns 
 */
const Dg1101: FC<ElementProps> = ({ value }) => {
    return <>{value}</>
}
export default Dg1101