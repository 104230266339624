import { createContext, useContext, useEffect, useState } from 'react'
import { ItemContextType } from '../types/ItemContextType'
import Element from '../components/Element'
import Item from '../types/Item'
import * as SiteService from '../services/SiteService'
import * as ItemService from '../services/ItemService'
import * as UserService from '../services/UserService'
import { SiteContext } from '../contexts/SiteContext'
import { collectFields, getCategory, getMultiParents, getValue, slugify } from '../utils/valueUtils'

export const ItemContext = createContext<ItemContextType>({
  item: {
    category: 0,
    status: 0,
    values: []
  },
  setItem: () => {
    throw new Error('Function not implemented.')
  },
  setEditable: () => {
    throw new Error('Function not implemented.')
  },
  refreshEmptyValue: () => {
    throw new Error('Function not implemented.')
  },
  save: () => {
    throw new Error('Function not implemented.')
  }
})

interface ItemProviderProps {
  layout: any
  item: Item
}

const removeEmptyValues = (item: any) => {
  for (let i in item) {
    if (item[i] === null || item[i] === false || item[i] === '') {
      // NULL эсвэл хоосон тэмдэгт утгатай шинж чанар бол устгана
      delete item[i]
    } else if (Array.isArray(item[i])) {
      // Массив бол
      item[i].forEach((prop: any, j: number) => {
        // Элемент бүр дээр цэвэрлэх үйлдлийг дуудна
        if (typeof prop === 'object' && !Array.isArray(prop)) {
          removeEmptyValues(prop)
          if (Object.keys(prop).length === 0 ||
            ('field' in prop && !('id' in prop) && !('value' in prop))) {
            // Массивын элемент хоосон обьект бол устгана
            delete item[i][j]
          }
        }
      })
      // Массивын хоосон элементүүдийг устгана
      item[i] = item[i].filter((prop: any) => { return prop != null })
      if (item[i].length === 0) {
        // Хоосон массив утгатай шинж чанар бол устгана
        delete item[i]
      }
    } else if (typeof item[i] === 'object') {
      // Обьект бол
      removeEmptyValues(item[i])
      if (Object.keys(item[i]).length === 0) {
        delete item[i]
      }
    }
  }
}
/**
* Өөрчлөгдөөгүй талбаруудыг устгана
* @param {object} item Өөрчлөгдсөн мэдээлэл
* @param {object} oItem Анхны мэдээлэл
* @returns 
*/
const removeUnchangedFields = (item: any, oItem: any) => {
  const result = Object.fromEntries(Object.entries(item).filter(([key, value]) =>
    ['id', 'category', 'values'].includes(key) ||
    (typeof value === 'object' && JSON.stringify(value) !== JSON.stringify(oItem[key])) ||
    (typeof value !== 'object' && value !== oItem[key])
  ))

  if (result.values) {
    result.values = (result.values as any[]).filter((value: any) => {
      // Харгалзах шинэ утгыг олно
      var index = oItem.values.findIndex((oValue: any) =>
        value && oValue.id === value.id)
      // Харгалзах шинэ утга байгаа эсэхийг шалгана
      if (index > -1) {
        // Харгалзах шинэ утга байвал
        if (value.value === oItem.values[index].value) {
          // Анхны утгатай ижил бол өөрчлөгдөөгүй учир шинээс хасна
          return false
        } else if (typeof value.value === 'object' && JSON.stringify(value.value) === JSON.stringify(oItem.values[index].value)) {
          return false
        }
      }
      return true
    })
  }
  // Анхны утгад байхгүй шинээр нэмэгдсэн утгууд хэвээрээ үлдэнэ
  return result
}

const getEditElements = (layout: any) => {
  const fields: any[] = []
  const travers = (current: any) => {
    const type = getValue(current, 'type')
    if (type < 1000) {
      // EditElement бол fields рүү нэмнэ.
      fields.push(current)
    }
    if (current.items) {
      current.items.forEach(travers)
    }
  }
  travers(layout)
  return fields
}

const addEmptyValues = (item: Item, fields: any[]) => {
  // Үндсэн мэдээллийг өөрчлөхгүйн тулд хувилж авна.
  let result: Item | null = null
  // Бүх талбараар дамжина.
  fields.forEach(current => {
    // Олон утга авдаг эсэхийг авна.
    const multi = getValue(current, 'multi')
    // Талбарын утгуудыг авна.
    const fieldValues = item.values?.filter((value: any) => value.field === current.id)
    // Талбарын хоосон утгыг авна.
    const emptyValue = fieldValues?.find((value: any) => value.value == null || value.value === '')
    // Талбарын утга байхгүй бол
    // эсвэл олон утга авдаг бөгөөд хоосон утгагүй бол
    if (!fieldValues || fieldValues.length === 0 || (multi && emptyValue == null)) {
      if (!result) {
        result = structuredClone(item)
      }
      // Хоосон утга нэмнэ.
      if (!result.values) {
        result.values = []
      }
      result.values.push({ field: current.id, value: null })
    }
  })
  return result ?? item
}

export const ItemProvider: React.FC<ItemProviderProps> = ({ layout, item }) => {
  // Вебийн тохиргоог агуулна.
  const { site } = useContext(SiteContext)
  const [_item, setItem] = useState<Item>(item)
  const [editable, setEditable] = useState(false)
  // Хуудас солигдох үед мэдээллийг шинээр дүрсэлнэ.
  useEffect(() => {
    setItem(item)
  }, [item])

  useEffect(() => {
    if (editable) {
      const fields = getEditElements(layout)
      setItem((prev: Item) => {
        const result = addEmptyValues(prev, fields)
        return result
      })
    }
  }, [editable, layout])

  // Утгагүй бол хоосон утга нэмнэ,
  // хэрвээ олон утга авдаг бөгөөд олон хоосон утгатай бол
  // нэг л хоосон утга үлдээнэ.
  const refreshEmptyValue = (properties: any) => {
    const multi = getValue(properties, 'multi')
    // Бүх утгаар дамжина.
    setItem((prev: any) => {
      const fieldValues = prev.values.filter((value: any) => value.field === properties.id)
      const emptyValue = fieldValues.find((value: any) => value.value == null || value.value === '')
      if (fieldValues.length === 0 || (multi && emptyValue == null)) {
        return { ...prev, values: [...prev.values, { field: properties.id, value: null }] }
      } else {
        return { ...prev, values: prev.values.filter((value: any) => value) }
      }
    })
  }
  // Мэдээллийн хуулбарыг хадгалах хувьсагч
  const optimizeUpdateData = (data: any) => {
    // name бичсэн бол зөвшөөрөгдөөгүй тэмдэгтүүдийг цэвэрлэнэ
    data.name = data.name ? slugify(data.name) : ''

    console.log('update', data, item)
    // эх өгөгдлийг хөндөхгүйн тулд хувилж авна
    let changedItem: any = removeUnchangedFields(data, item)
    // хоосон талбаруудыг цэвэрлэнэ
    removeEmptyValues(changedItem)
    if (changedItem.category > 0) {
      // олон ангилалд багтдаг бол эх ангиллуудыг нь оруулна
      changedItem.categories = getMultiParents(site, getCategory(site, changedItem.category))
    }
    return changedItem
  }
  const optimizeInsertData = (data: any) => {
    data.name = data.name ? slugify(data.name) : ''
    var item = { ...data }
    removeEmptyValues(item)
    if (item.category > 0) {
      item.categories = getMultiParents(site, getCategory(site, item.category))
    }
    return item
  }
  // Мэдээллийн түлхүүр утга болон өөрчлөгдсөн хэсгийг серверт илгээнэ
  const save = async () => {
    // Item нь ангилалтай бол үндсэн мэдээлэл гэж үзээд хадгалах үйлдэл хийнэ.
    if ('category' in _item) {
      // bind хийсэн утгуудыг олгоно
      collectFields(layout.items).forEach((field: any) => {
        if (field.bind > 0 && !(item as any)[field.id] && (item as any)[field.id] !== '') {
          (_item as any)[field.id] = getValue(item, field.bind)
        }
      })
      // Мэдээллийн төрлөөс хамаарч тохирох сервисийг ажиллуулна
      if (_item.category === 0) {
        // Хэрвээ веб сайтын тохиргоо бол
        const clear = (value: any) => {
          if (value && typeof value === 'object') {
            if ('parentId' in value) {
              delete value.parentId
            }
            if ('items' in value) {
              if (value.items.length > 0) {
                value.items.forEach((child: any) => clear(child))
              } else {
                delete value.items
              }
            }
            if ('path' in value) {
              delete value.path
            }
          }
        }
        _item.values?.forEach((value: any) => clear(value.value))
        delete _item.default
        await SiteService.edit(optimizeUpdateData(_item))
      } else if (_item.category === -3) {
        // Хэрвээ хэрэглэгч бол
        if ((_item.id || 0) > 0) {
          await UserService.edit(optimizeUpdateData(_item))
        } else {
          const data = await (await UserService.add(optimizeInsertData(_item))).json()
          _item.id = data.id
        }
      } else if ((_item.id || 0) > 0) {
        // Хэрвээ өмнө нь оруулсан мэдээлэл бол
        await ItemService.edit(optimizeUpdateData(_item))
      } else {
        // Хэрвээ шинээр оруулж буй мэдээлэл бол
        const data = await (await ItemService.add(optimizeInsertData(_item))).json()
        _item.id = data.id
      }
    } else {
      Object.assign(item, _item)
    }
  }
  // Модал цонх дүрслэх үед _item хоосон утгатай байх тул утга байгаа эсэхийг шалгав.
  return _item && <ItemContext.Provider value={{ item: _item, setItem, setEditable, refreshEmptyValue, save }}>
    <Element properties={layout} />
  </ItemContext.Provider>
}
