import { FC } from 'react'
import * as valueUtils from '../utils/valueUtils'
import { ModalProvider } from '../contexts/ModalContext'

const JsonModal: FC<JsonModalProps> = ({ item, visible, accept, cancel }) => {
    const layout = require('../json/json.json')
    const title = 'JSON'
    const _item = { values: [{ field: "desc", value: JSON.stringify(item) }] }
    const handleAccept = () => {
        accept(JSON.parse(valueUtils.getValue(_item, 'desc')))
    }
    return <ModalProvider layout={layout} item={_item}
        title={title} visible={visible}
        accept={handleAccept} cancel={cancel} />
}
type JsonModalProps = {
    visible: boolean
    item: any
    accept: any
    cancel: any
}

export default JsonModal