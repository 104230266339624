import * as ItemService from "../services/ItemService"
import * as UserService from "../services/UserService"
import * as SiteService from "../services/SiteService"

const itemLoader = async (route: any) =>
    route.params.itemId === 'new' ?
        { category: Number(route.params.categoryId), status: 30, values: [] } :
        route.params.categoryId === '0' && route.params.itemId === '0' ?
            await SiteService.get() :
            route.params.categoryId === '-3' ?
                await UserService.get(route.params.itemId) :
                await ItemService.get(route.params.itemId)


export default itemLoader