import { FC, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import * as ItemService from '../../services/ItemService'
import WidgetProps from '../../types/WidgetProps'
import { SiteContext } from '../../contexts/SiteContext'
import { getValue, getCategory } from '../../utils/valueUtils'
import Pagination from '../Pagination'
import { ItemProvider } from '../../contexts/ItemContext'

const getPage = () => {
    const page = Number(new URL(window.location.href).searchParams.get('page') || '1')
    return page > 1 ? page : 1
}
// Item list
const Dg5007: FC<WidgetProps> = ({ properties }) => {
    const { site, getItemLayout } = useContext(SiteContext)
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState([] as any[])
    const page = { current: getPage(), size: 0, total: 0 }
    var limit = getValue(properties, 'count')
    var search = getValue(properties, 'search')
    var catId = getValue(properties, 'category')
    var pntId = getValue(properties, 'parent')
    const pagination = getValue(properties, 'pagination')
    const layoutName = getValue(properties, 'layout')
    const column = getValue(properties, 'column')
    const auto = getValue(properties, 'auto')
    const duration = getValue(properties, 'duration') || 5000
    const delay = getValue(properties, 'delay') || 0
    const className = column > 1 ? ' has-' + column + '-cols' : ''
    var itemsDiv: any
    var timer: any
    let current = 0
    var run = function () {
        next()
    }
    const collectCategories = (category: any) => {
        var result = [category.id]
        // Ангилал нь дэд ангиллуудын мэдээллийг агуулах бол
        // удамшсан бүтэцтэй ангиллуудыг дугаарыг цуглуулна
        if (getValue(category, 'multi') && 'items' in category) {
            for (var child of category.items) {
                if (getValue(child, 'inherit')) {
                    result = result.concat(collectCategories(child))
                }
            }
        }
        return result
    }
    const [state, setState] = useState({})
    useEffect(() => {
        setIsLoading(true)
        var queryParam: any = {}
        queryParam.limit = limit > 0 ? limit : 50
        queryParam.offset = (page.current - 1) * queryParam.limit
        var q: any = {
            $orderby: { created: 'desc' }
        }
        if (pntId > 0) {
            q.parent = pntId
        }
        if (search != null) {
            q.search = search
        }
        if (Array.isArray(catId)) {
            q.category = { $in: catId }
        } else {
            let category = getCategory(site, catId)
            if (category) {
                var catIds = collectCategories(category)
                q.category = catIds.length > 1 ? { $in: catIds } : catIds[0]
            }
        }
        queryParam.q = JSON.stringify(q)
        ItemService.query(queryParam).then(response => response.json()).then(function (value) {
            setItems(value.content)
            page.size = value.count
            page.total = value.total
            setIsLoading(false)
            // TODO: slide
        })
    }, [limit, pntId, catId, search, page.current])
    const prev = () => {
        var children = itemsDiv.getElementsByClassName("item")
        itemsDiv.insertBefore(children[children.length - 1], children[0])
        current = current > 0 ? current - 1 : items.length - 1
        if (auto) {
            clearTimeout(timer)
            timer = setTimeout(run, duration)
        }
    }
    const next = () => {
        var children = itemsDiv.getElementsByClassName("item")
        itemsDiv.appendChild(children[0])
        current = current < items.length - 1 ? current + 1 : 0
        if (auto) {
            clearTimeout(timer)
            timer = setTimeout(run, duration)
        }
    }
    const goTo = (item: any) => {
        var children = itemsDiv.getElementsByClassName("item")
        var to = items.indexOf(item)
        if (current < to) {
            for (var i = current; i < to; i++) {
                itemsDiv.appendChild(children[0])
            }
        } else {
            for (var i = to; i < current; i++) {
                itemsDiv.insertBefore(children[children.length - 1], children[0])
            }
        }
        current = to
        if (auto) {
            clearTimeout(timer)
            timer = setTimeout(run, duration)
        }
    }
    return <div className={properties.class}>
        <div className={"items " + className}>
            {items.map((item: any, i: number) => <Link key={i} to={'/items/' + item.id + '.html'} className="item">
                <ItemProvider item={item} layout={getItemLayout(getCategory(site, item.category), layoutName)} />
            </Link>)}
        </div>
        {layoutName === 'slide' && <div className="nav">
            <a href="" onClick={prev} className="prev"></a>
            <div className="dots">
                {items.map((item, i) => <a key={i} href="" className={'dot ' + (i === current ? 'active' : null)} onClick={() => { goTo(item) }}></a>)}
            </div>
            <a href="" onClick={next} className="next"></a>
        </div>
        }
        {pagination && <Pagination total={page.total} count={items.length}
            size={page.size} current={page.current} />}
    </div>
}
export default Dg5007