import { useContext, useEffect, useState } from 'react'
import { Link, useLoaderData, useParams } from 'react-router-dom'
import * as ItemService from '../services/ItemService'
import { getCategory, collectFields, getValue } from '../utils/valueUtils'
import Pagination from '../components/Pagination'
import { SiteContext } from '../contexts/SiteContext'
import PermissionModal from '../modal/PermissionModal'
import ExcelModal from '../modal/ExcelModal'
import { ItemProvider } from '../contexts/ItemContext'

function getUrl(i: any) {
    var url = new URL(window.location.toString())
    var search_params = url.searchParams
    // new value of "id" is set to "101"
    search_params.set('status', i)
    // change the search property of the main url
    url.search = search_params.toString()
    // the new url string
    return url.pathname + url.search
}
function Table() {
    const { categoryId } = useParams()
    const { site, getItemLayout } = useContext(SiteContext)
    const data = useLoaderData() as any
    const items = data.content
    const [fields, setFields] = useState([] as any[])
    const [visible, setVisible] = useState(false)
    const [excelVisible, setExcelVisible] = useState(false)
    const [permission, setPermission] = useState(null as any)
    const [title, setTitle] = useState('')
    const url = new URL(window.location.href)
    const status = Number(url.searchParams.get('status')) > 0 ?
        Number(url.searchParams.get('status')) : categoryId === '-3' ? 20 : 30
    const [category, setCategory] = useState<any>()
    const parent = Number(url.searchParams.get('parent'))
    const pagination = {
        current: Number(url.searchParams.get('page')) > 1 ? Number(url.searchParams.get('page')) : 1,
        size: Number(url.searchParams.get('size')) > 1 ? Number(url.searchParams.get('size')) : 10
    }
    const hasForm = () => {
        return category && getItemLayout(category, 'form').items?.length > 0
    }

    const remove = (item: any) => {
        if (window.confirm('Та "' + item.title + '" мэдээллийг устгаж байгаадаа итгэлтэй байна уу?')) {
            ItemService.remove(item).then(() => {
                //setItems(items.filter(itm => itm !== item))
            })
        }
    }
    const changeParam = (category: any) => {
        setVisible(true)
        setPermission({
            permission: category.param && category.param.itemPermission ?
                category.param.itemPermission : {},
            //TODO: defperm: site.defaults[0].param.itemPermission
            defperm: site.default.param
        })
        setTitle(getValue(category, 'title') + ' - ангилалд хандах эрх')
    }
    const onPermissionClose = (permission: any) => {
        if (!('param' in category)) {
            category.param = {}
        }
        category.param.itemPermission = permission
    }
    const excel = () => {
        setExcelVisible(true)
    }
    const onExcelClose = (result: any) => {
        //setItems(items.concat(result))
    }
    useEffect(() => {
        const cat = getCategory(site, Number(categoryId))
        if (cat) {
            var value = getItemLayout(cat, 'teaser')
            setFields(collectFields(value.items))
        }
        setCategory(cat)
    }, [site, categoryId])

    const renderHeader = () => <tr>
        <th className="action"></th>
        <th className="id">№</th>
        {fields.map((field, i) => <th key={i}>{getValue(field, 'title')}</th>)}
        <th className="action"></th>
    </tr>

    return <div className="table-view">
        <div className="page-head">
            <h1><i className="fa fa-folder-o fa-fw"></i> {category && getValue(category, 'title')}</h1>
        </div>
        <div className="box">
            <div className="action">
                <Link to={getUrl(30)} className={status === 30 ? 'btn btn-primary' : 'btn'} title="Нийтэлсэн">
                    <i className="fa fa-eye"></i></Link>
                <Link to={getUrl(20)} className={status === 20 ? 'btn btn-primary' : 'btn'} title="Нийтлээгүй">
                    <i className="fa fa-eye-slash"></i></Link>
                <Link to={getUrl(10)} className={status === 10 ? 'btn btn-primary' : 'btn'} title="Хаалттай">
                    <i className="fa fa-ban"></i></Link>
                {hasForm() && <a href="" onClick={excel} className="btn btn-default">
                    <i className="fa fa-file-excel-o"></i> Excel-ээс нэмэх</a>}
                {hasForm() && <Link to={parent ? "/items/" + parent +
                    "/categories/" + category.id + "/items/new/form.html" :
                    '/categories/' + category.id + '/items/new/form.html'} className="btn btn-success">
                    <i className="fa fa-plus"></i> Шинийг нэмэх</Link>}
                <a href="" className="btn btn-default" onClick={changeParam} title="Тохируулах">
                    <i className="fa fa-gear"></i></a>
            </div>
            <Pagination total={data.total} count={items.length} size={pagination.size} current={pagination.current} />
            <div className="table-wrapper">
                <table className="table table-striped table-bordered table-hover">
                    <thead>
                        {renderHeader()}
                    </thead>
                    <tbody>
                        {items.map((item: any) => <tr key={item.id}>
                            <td className="edit">
                                <Link className="btn btn-primary btn-sm" to={Number(categoryId) < 0 ? '/categories/' + categoryId + '/items/' + item.id + '/form.html' : '/items/' + item.id + '/form.html'}><i className="fa fa-pencil"></i></Link>
                            </td>
                            <td className="id">{item.id}</td>
                            {fields.map((field: any, i: number) => <td key={i} className={'f' + field.id}>
                                <ItemProvider item={item} layout={field} />
                            </td>)}
                            <td className="remove">
                                <button className="btn btn-danger btn-sm" onClick={() => { remove(item) }}><i className="fa fa-times"></i></button>
                            </td>
                        </tr>)}
                    </tbody>
                    <tfoot>
                        {renderHeader()}
                    </tfoot>
                </table>
            </div>
            <Pagination total={data.total} count={items.length} size={pagination.size} current={pagination.current} />
            <PermissionModal title={title} visible={visible} onAccept={onPermissionClose} onCancel={() => setVisible(false)} item={permission} />
            {category && category.id ? <ExcelModal category={category} pntId={parent} visible={excelVisible} onClose={onExcelClose} item={undefined} title={''} /> : null}
        </div>
    </div>
}
export default Table