import { useState, useEffect, FC, useContext } from 'react'
import { useParams } from 'react-router-dom'
import EditElementProps from '../../types/EditElementProps'
import { SiteContext } from '../../contexts/SiteContext'
import { getFields, getValue } from '../../utils/valueUtils'
import { ItemContext } from '../../contexts/ItemContext'
// field-edit
// системийн талбар. Утгаа шууд item[properties.id] руу хадгална
const Dg14: FC<EditElementProps> = ({ properties, onChange, value }) => {
    const { item } = useContext(ItemContext)
    const { site } = useContext(SiteContext)
    const { nodeId } = useParams()
    const [options, setOptions] = useState([] as [any, any][])
    const title = getValue(properties, 'title')
    const required = getValue(properties, 'required')
    const readonly = getValue(properties, 'readonly')
    useEffect(() => {
        var catId = nodeId ? Number(nodeId) : item.category
        var options = getValue(properties, 'options')
        if (options === null) {
            setOptions(getFields(site, catId).map(function (field) {
                return [field.id, getValue(field, 'title')]
            }))
        } else {
            setOptions(Object.keys(options).map(function (key) {
                return [key, options[key]]
            }))
        }
    })
    const handleChange = (event: any) => {
        onChange(event.target.value)
    }
    return <select required={required} disabled={readonly} value={value} onChange={handleChange}>
        <option value="">---{title}---</option>
        {options.map((option: any, i) => <option key={i} value={option[0]}>{option[1]}</option>)}
    </select>
}
export default Dg14