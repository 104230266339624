import { FC, useEffect, useState } from 'react'
import EditElementProps from '../../types/EditElementProps'
import { getValue } from '../../utils/valueUtils'
// matrix-check-edit
const Dg42: FC<EditElementProps> = ({ properties, onChange, value }) => {
	const options = getValue(properties, 'options')
	const fields = getValue(properties, 'fields')
	const [useDefault, setUseDefault] = useState<any>([])

	useEffect(() => {
		if (useDefault.length === 0) {
			setUseDefault(fields.map((field: any) => [
				field[0],
				value.current.find((item: any) => item[0] === field[0]) === undefined]))
		}
	})

	const optionGroups = options.reduce((acc: any, option: any) => {
		const key = option[2] || "";
		if (!acc[key]) acc[key] = [];
		acc[key].push(option);
		return acc;
	}, {});

	const getFieldValue = (key: any) => {
		if (isDefault(key)) {
			const defaultPair = value.default.find((item: any) => item[0] === key)
			return defaultPair ? defaultPair[1] : 0
		} else {
			const pair = value.current.find((item: any) => item[0] === key)
			return pair ? pair[1] : 0
		}
	}

	const isDefault = (key: any) => {
		const pair = useDefault.find((item: any) => item[0] === key)
		return pair ? pair[1] : true
	}

	const setFieldValue = (isChecked: boolean, key: any, option: number) => {
		let pair = value.current.find((item: any) => item[0] === key)
		if (!pair) {
			pair = [key, 0]
			value.current.push(pair)
		}
		pair[1] = isChecked ? pair[1] | (1 << option) : pair[1] & ~(1 << option)
		onChange({ ...value })
	}

	const handleChangeDefault = (isChecked: boolean, key: any) => {
		let pair = useDefault.find((item: any) => item[0] === key)
		pair[1] = isChecked
		setUseDefault([...useDefault])
	}

	return options && <div className="grid-8" style={{ display: 'grid', gridTemplateColumns: 'repeat(15, 1fr)' }}>
		<label></label>
		{Object.keys(optionGroups).map((group: any) => <label title={group} style={{ gridColumn: `span ${optionGroups[group].length}` }}>{group}</label>)}
		<label></label>
		<label></label>
		{options.map((option: any[]) => <label key={option[0]} title={option[1]} className={'v' + option[0]}>{option[1]}</label>)}
		<label>Анхны утгаараа</label>
		{fields.map((field: any[]) => <>
			<label>{field[1]}</label>
			{options.map((option: any[]) => <input type="checkbox" key={option[0]}
				disabled={isDefault(field[0])}
				checked={(getFieldValue(field[0]) & (1 << option[0])) === (1 << option[0])}
				onChange={e => setFieldValue(e.target.checked, field[0], option[0])} />)}
			<input type="checkbox" defaultChecked={isDefault(field[0])} onChange={e => handleChangeDefault(e.target.checked, field[0])} />
		</>)}
	</div>
}
export default Dg42