import * as ItemService from "../services/ItemService"

const itemsLoader = async (route: any) => {
    const url = new URL(route.request.url)
    const status = Number(url.searchParams.get('status')) > 0 ?
        Number(url.searchParams.get('status')) : route.params.categoryId === '-3' ? 20 : 30
    const parentId = Number(url.searchParams.get('parent'))
    const current = Number(url.searchParams.get('page')) > 1 ? Number(url.searchParams.get('page')) : 1
    const limit = Number(url.searchParams.get('size')) > 1 ? Number(url.searchParams.get('size')) : 10

    const q: any = {
        status: status,
        category: Number(route.params.categoryId),
        $orderby: { created: 'desc' }
    }

    if (parentId > 0) {
        q.parent = parentId
    }
    const queryParam = { limit: limit, q: JSON.stringify(q), offset: (current - 1) * limit }
    return await ItemService.query(queryParam)
}

export default itemsLoader